<template>
  <div>
    <c-card title="사진추가" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-12">
          <c-upload 
            :attachInfo="attachInfo1"
            label="원거리"
            :editable="editable&&!disabled">
          </c-upload>
        </div>
        <div class="col-12">
          <c-upload 
            :attachInfo="attachInfo2"
            label="근거리"
            :editable="editable&&!disabled">
          </c-upload>
        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
export default {
  name: 'nearRelationPicture',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimNearAccidentId: '',
      }),
    },
    attachInfo1: {
      type: Object,
      default: function() {
        return {
          isSubmit: '',
          taskClassCd: 'NEAR_FIRST_PICTURE',
          taskKey: '',
        }
      },  
    },
    attachInfo2: {
      type: Object,
      default: function() {
        return {
          isSubmit: '',
          taskClassCd: 'NEAR_SECOND_PICTURE',
          taskKey: '',
        }
      },  
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      editable: true,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
  }
};
</script>
  